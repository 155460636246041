import { AxiosError } from "axios";
import { createApp } from "vue";
import * as Sentry from "@sentry/vue";
import { Event, EventHint } from "@sentry/types";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import { VueSvgIconPlugin } from "@yzfe/vue3-svgicon";
import { createGtm } from "@gtm-support/vue-gtm";

import App from "@/App.vue";
import router from "@/router";
import { pinia } from "@/stores";
import { useAccountStore } from "@/stores/account";
import dayjsPlugin from "@/plugins/dayjs";
import pageTitleMixin from "@/mixins/pageTitle";
import openAPIClientAxios from "@/plugins/openAPIClientAxios";

import "@yzfe/svgicon/lib/svgicon.css";
import "./assets/scss/gentwo-pro/main.scss";
import VueDOMPurifyHTML from 'vue-dompurify-html';

const app = createApp(App)
  .use(openAPIClientAxios)
  .use(pinia)
  .use(router)
  .use(dayjsPlugin)
  .use(PrimeVue)
  .use(ToastService)
  .use(ConfirmationService)
  .mixin(pageTitleMixin)
  .use(VueSvgIconPlugin, { tagName: "icon" })
  .use(VueDOMPurifyHTML);

if (import.meta.env.VITE_APP_ENVIRONMENT === "staging") {
  app.use(
    createGtm({
      id: "GTM-K4TV433",
      defer: false,
      enabled: true,
      debug: true,
      loadScript: true,
      vueRouter: router,
    }),
  );
} else if (import.meta.env.PROD) {
  app.use(
    createGtm({
      id: "GTM-W3NHN94",
      defer: false,
      enabled: true,
      debug: false,
      loadScript: true,
      vueRouter: router,
    }),
  );
  Sentry.init({
    app,
    dsn: "https://6d0579917c4046759d628e25e13e5dbf@o185975.ingest.sentry.io/1280961",
    ignoreErrors: ["ChunkLoadError"],
    release: import.meta.env.VITE_APP_VERSION,
    environment: import.meta.env.VITE_APP_ENVIRONMENT,
    attachStacktrace: true,
    integrations: [
      Sentry.browserTracingIntegration({router}),
    ],
    beforeSend(event, hint) {
      // we do not want to log the 400, 401 and 403 to sentry because
      // - for the user they are handeled in api.js
      // - we do (miss)use 400 return from the MVCController as a valid return code
      const error: AxiosError = hint.originalException as AxiosError;

      if (
        error?.message &&
        (error.message.match(/Network Error/i) ||
          error.message.match(/ChunkLoadError/i) ||
          error.message.includes("dynamically imported module") ||
          error.message.includes("module script failed") ||
          error.message.includes("Unable to preload CSS"))
      ) {
        return null;
      }

      if (
        error?.response?.status &&
        (error.response.status === 400 ||
          error.response.status === 401 ||
          error.response.status === 403 ||
          error.response.status === 409)
      ) {
        return null;
      }

      if (event.exception) {
        const account = useAccountStore();

        Sentry.showReportDialog({
          eventId: event.event_id,
          user: {
            name: account.user?.firstName + " " + account.user?.lastName,
            email: account.user?.email,
          },
          title: "Sorry, we've had a problem processing this",
          subtitle: "The Gentwo Development team has already been notified.",
          subtitle2:
            "If you'd like to help us further, please tell us what happened below. If this is impacting your business, please email dev@gentwo.com or call us on +41 44 512 75 00",
        });
      }
      return event;
    },
  });
}
app.mount("#app");
