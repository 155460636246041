<template>
  <div class="profile-menu">
    <Button
      class="profile-button p-d-flex p-ai-center p-jc-center p-flex-1"
      @click="toggleMenu"
      aria-haspopup="true"
      aria-controls="profile_menu"
      :outlined="!menuVisible"
      :class="{ collapsed: collapsed }"
    >
      <Avatar
        :firstName="user.firstName"
        :lastName="user.lastName"
        :src="user.avatar"
        size="large"
        :border="user.isImpersonating"
      />
      <div class="p-ml-3 p-text-left">
        <h6 class="p-mt-0 p-mb-1">{{ user.firstName }} {{ user.lastName }}</h6>
        <span class="text-color-secondary">{{ userRole }}</span>
      </div>
      <icon
        data="@icon/arrow-down.svg"
        :fill="false"
        :dir="menuVisible ? 'down' : 'up'"
        width="1.5em"
        height="1.5em"
        class="arrow-down text-color-secondary p-ml-auto"
      />
    </Button>
    <Menu
      ref="profileMenu"
      :model="menuItems"
      :popup="true"
      @blur="toggleMenu"
      appendTo=".profile-menu"
    >
      <template #item="{ item, props }">
        <router-link v-if="item.to" v-slot="{ href, navigate }" :to="item.to" custom>
          <a :href="href" v-bind="props.action" @click="navigate">
            <icon :data="item.icon" :fill="false" class="p-menuitem-icon" />
            <span class="p-menuitem-text" data-pc-section="label">{{ item.label }}</span>
          </a>
        </router-link>
        <a v-else v-bind="props.action">
          <icon :data="item.icon" :fill="false" class="p-menuitem-icon" />
          <span class="p-menuitem-text" data-pc-section="label">{{ item.label }}</span>
        </a>
      </template>
    </Menu>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Bowser from "bowser";
import Button from "primevue/button";
import Menu from "primevue/menu";
import Avatar from "@/components/elements/Avatar";
import { useAccountStore } from "@/stores/account";
import type { User } from "@/stores/account";

export default defineComponent({
  name: "ProfileMenu",
  components: {
    Button,
    Menu,
    Avatar,
  },
  setup: () => {
    return { accountStore: useAccountStore() };
  },
  props: {
    user: Object as User,
    collapsed: Boolean,
    showLogout: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loggingOut: false,
      menuVisible: false,
      colorModes: [
        { mode: "light", label: "Light mode", icon: require("@icon/light-mode.svg") },
        { mode: "dark", label: "Dark mode", icon: require("@icon/dark-mode.svg") },
      ],
    };
  },
  computed: {
    userRole() {
      // TODO: get proper Role
      return this.user.roles[0].replace("Role", "");
    },
    menuItems() {
      return [
        { label: "Settings", icon: require("@icon/settings.svg"), to: { name: "UserProfile" } },
        ...(!this.isOldSafari
          ? [
              {
                label: `Switch to ${this.colorModes[0].label}`,
                icon: this.colorModes[0].icon,
                command: () => {
                  this.accountStore.setUserTheme(this.colorModes[0].mode);
                  this.nextColorMode();
                },
              },
            ]
          : []),
        {
          label: "Log Out",
          icon: require("@icon/logout.svg"),
          command: async () => {
            this.loggingOut = true;

            await this.accountStore.logout();
            this.$router.push({ name: "Login" });

            this.loggingOut = false;
          },
          visible: () => this.showLogout,
        },
      ];
    },
    isOldSafari() {
      const browser = Bowser.getParser(window.navigator.userAgent);
      return browser.satisfies({
        safari: "<17",
      });
    },
  },
  mounted() {
    if (!window.matchMedia) {
      return false;
    }
    const lightThemeQuery = window.matchMedia("(prefers-color-scheme: light)");

    if (this.user.theme === "light" || (this.user.theme === "system" && lightThemeQuery.matches)) {
      this.nextColorMode();
    } else {
      this.nextColorMode();
      this.nextColorMode();
    }

    lightThemeQuery.addEventListener("change", () => {
      if (this.user.theme === "system") {
        this.nextColorMode();
      }
    });
  },
  methods: {
    toggleMenu(event) {
      if (!event.relatedTarget) {
        this.$refs.profileMenu?.toggle(event);
        this.menuVisible = this.$refs.profileMenu?.overlayVisible;
      }
    },
    nextColorMode() {
      const previousColorMode = this.colorModes.shift();
      this.colorModes.push(previousColorMode);
    },
  },
});
</script>

<style scoped lang="scss">
.profile-menu {
  display: flex;
  position: relative;
  box-shadow: 0px 6px 8px 20px var(--nav-menu-bg-color);

  .profile-button {
    text-transform: none;
    box-shadow: none !important;

    border: 1px solid;
    &.p-button-outlined {
      border-color: var(--nav-menu-active-bg-color);
      background-color: transparent;
      &:hover {
        color: inherit;
        background: var(--nav-menu-active-bg-color);
      }
    }

    &:not(.p-button-outlined) {
      color: inherit;
      background: transparent;
      border-color: var(--nav-menu-active-bg-color);
      &:hover {
        color: inherit;
        background: var(--nav-menu-active-bg-color);
      }
    }

    .arrow-down {
      transition: all 0.2s ease-in-out;
    }

    &.collapsed {
      padding-left: 0;
      padding-right: 0;
      border-color: transparent !important;
      background-color: transparent !important;

      & > *:not(.avatar) {
        display: none;
      }

      & + .p-menu-overlay {
        .p-menuitem-link {
          padding-left: 1.3rem;
        }
      }
    }
  }
  :deep() {
    .p-menu {
      width: 100%;
      min-width: 0;
      top: auto !important;
      bottom: 100% !important;
      left: 0 !important;
      transform: translateY(-10px);
    }
  }
}
</style>
